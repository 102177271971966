<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <b-card
          border-variant="primary"
          header="Datos personales"
          header-bg-variant="primary"
          header-text-variant="white"
        >
          <b-card-text>
            <div class="row">
              <div class="col-md-4">
                <b-form-group label="Primer apellido">
                  <b-form-input
                    v-model="form.last_name"
                    type="text"
                    placeholder="Ingrese primer apellido"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <b-form-group label="Segundo apellido">
                  <b-form-input
                    v-model="form.last_name_2"
                    type="text"
                    placeholder="Ingrese segundo apellido"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <b-form-group label="Nombres">
                  <b-form-input
                    v-model="form.name"
                    type="text"
                    placeholder="Ingrese nombres"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <b-form-group label="Sexo">
                  <b-form-input
                    v-model="form.gender"
                    type="text"
                    placeholder="Sexo"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <b-form-group label="Tipo documento">
                  <b-form-input
                    v-model="form.document_type"
                    type="text"
                    placeholder="Sexo"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <b-form-group label="Documento">
                  <b-form-input
                    v-model="form.dni"
                    type="text"
                    placeholder="Sexo"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <b-form-group label="Fecha de nacimiento">
                  <b-form-input
                    v-model="form.birth_date"
                    type="date"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
          </b-card-text>
        </b-card>
        <b-card
          border-variant="primary"
          header="Datos de contacto"
          header-bg-variant="primary"
          header-text-variant="white"
        >
          <b-card-text>
            <div class="row">
              <div class="col-md-4">
                <b-form-group label="Celular">
                  <b-form-input
                    v-model="form.celular"
                    type="text"
                    placeholder="Ingrese celular"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <b-form-group label="Email">
                  <b-form-input
                    v-model="form.email"
                    type="email"
                    placeholder="Ingrese email"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
          </b-card-text>
        </b-card>

        <b-card
          border-variant="primary"
          header="TRIAJE"
          header-bg-variant="primary"
          header-text-variant="white"
        >
          <b-card-text>
            <div class="row">
              <b-table-simple hover small caption-top responsive class="mb-3">
                <b-thead>
                  <b-tr>
                    <b-th>SIGNOS VITALES</b-th>
                    <b-th>VALOR</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr>
                    <b-th>Temperatura</b-th>
                    <b-th>
                      <b-form-input
                        v-model="triage_form.temperature"
                        type="text"
                        required
                      ></b-form-input>
                    </b-th>
                  </b-tr>
                  <b-tr>
                    <b-th>Presión arterial (PA)</b-th>
                    <b-th>
                      <b-form-input
                        v-model="triage_form.pa"
                        type="text"
                        required
                      ></b-form-input>
                    </b-th>
                  </b-tr>
                  <b-tr>
                    <b-th>Saturación (SAT)</b-th>
                    <b-th>
                      <b-form-input
                        v-model="triage_form.sat"
                        type="text"
                        required
                      ></b-form-input>
                    </b-th>
                  </b-tr>
                  <b-tr>
                    <b-th>Frecuencia cardíaca (FC)</b-th>
                    <b-th>
                      <b-form-input
                        v-model="triage_form.fc"
                        type="text"
                        required
                      ></b-form-input>
                    </b-th>
                  </b-tr>
                  <b-tr>
                    <b-th>Frecuencia respiratoria (FR)</b-th>
                    <b-th>
                      <b-form-input
                        v-model="triage_form.fr"
                        type="text"
                        required
                      ></b-form-input>
                    </b-th>
                  </b-tr>
                </b-tbody>
              </b-table-simple>

              <b-table-simple hover small caption-top responsive class="mb-3">
                <b-thead>
                  <b-tr>
                    <b-th>DATOS ANTROPOMÉTRICOS</b-th>
                    <b-th>VALOR</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr>
                    <b-th>Peso</b-th>
                    <b-th>
                      <b-form-input
                        v-model="triage_form.weight"
                        type="text"
                        required
                      ></b-form-input>
                    </b-th>
                  </b-tr>
                  <b-tr>
                    <b-th>Talla</b-th>
                    <b-th>
                      <b-form-input
                        v-model="triage_form.height"
                        type="text"
                        required
                      ></b-form-input>
                    </b-th>
                  </b-tr>
                  <b-tr>
                    <b-th>Perímetro abdominal (PAB)</b-th>
                    <b-th>
                      <b-form-input
                        v-model="triage_form.pab"
                        type="text"
                        required
                      ></b-form-input>
                    </b-th>
                  </b-tr>
                </b-tbody>
              </b-table-simple>

              <b-table-simple hover small caption-top responsive class="mb-3">
                <b-thead>
                  <b-tr>
                    <b-th>OTROS DATOS</b-th>
                    <b-th>VALOR</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr>
                    <b-th>Es gestante</b-th>
                    <b-th>
                      <b-form-checkbox
                        v-model="triage_form.is_pregnant"
                        size="lg"
                      ></b-form-checkbox>
                    </b-th>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </div>
          </b-card-text>
        </b-card>

        <b-card
          border-variant="primary"
          header="Antecedentes patológicos"
          header-bg-variant="primary"
          header-text-variant="white"
        >
          <b-card-text>
            <div class="row">
              <b-table-simple hover small caption-top responsive>
                <b-thead>
                  <b-tr>
                    <b-th>ENFERMEDAD</b-th>
                    <b-th>OBSERVACIONES</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr>
                    <b-th>Tuberculosis</b-th>
                    <b-th>
                      <b-form-input
                        v-model="background_form.tuberculosis"
                        type="text"
                        required
                      ></b-form-input>
                    </b-th>
                  </b-tr>
                  <b-tr>
                    <b-th>SOBA/Asma</b-th>
                    <b-th>
                      <b-form-input
                        v-model="background_form.soba_asma"
                        type="text"
                        required
                      ></b-form-input>
                    </b-th>
                  </b-tr>
                  <b-tr>
                    <b-th>Epilepsia</b-th>
                    <b-th>
                      <b-form-input
                        v-model="background_form.epilepsia"
                        type="text"
                        required
                      ></b-form-input>
                    </b-th>
                  </b-tr>
                  <b-tr>
                    <b-th>Infecciones</b-th>
                    <b-th>
                      <b-form-input
                        v-model="background_form.infecciones"
                        type="text"
                        required
                      ></b-form-input>
                    </b-th>
                  </b-tr>
                  <b-tr>
                    <b-th>Hospitalizaciones</b-th>
                    <b-th>
                      <b-form-input
                        v-model="background_form.hospitalizaciones"
                        type="text"
                        required
                      ></b-form-input>
                    </b-th>
                  </b-tr>
                  <b-tr>
                    <b-th>Transfusiones de sangre</b-th>
                    <b-th>
                      <b-form-input
                        v-model="background_form.transfusiones"
                        type="text"
                        required
                      ></b-form-input>
                    </b-th>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </div>
          </b-card-text>
        </b-card>

        <b-card
          border-variant="primary"
          header="Antecedentes familiares"
          header-bg-variant="primary"
          header-text-variant="white"
        >
          <b-card-text>
            <div class="row">
              <b-table-simple hover small caption-top responsive>
                <b-thead>
                  <b-tr>
                    <b-th>ENFERMEDAD</b-th>
                    <b-th>PARENTESCO</b-th>
                    <b-th>OBSERVACIONES</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr>
                    <b-th>Tuberculosis</b-th>
                    <b-th>
                      <b-form-checkbox-group
                        v-model="familiar_background_form.tuberculosis_choices"
                        :options="options"
                        name="flavour-1a"
                      ></b-form-checkbox-group>
                    </b-th>
                    <b-th>
                      <b-form-input
                        v-model="familiar_background_form.tuberculosis"
                        type="text"
                      ></b-form-input>
                    </b-th>
                  </b-tr>
                  <b-tr>
                    <b-th>SOBA/Asma</b-th>
                    <b-th>
                      <b-form-checkbox-group
                        v-model="familiar_background_form.soba_asma_choices"
                        :options="options"
                        name="flavour-1a"
                      ></b-form-checkbox-group>
                    </b-th>
                    <b-th>
                      <b-form-input
                        v-model="familiar_background_form.soba_asma"
                        type="text"
                      ></b-form-input>
                    </b-th>
                  </b-tr>
                  <b-tr>
                    <b-th>Epilepsia</b-th>
                    <b-th>
                      <b-form-checkbox-group
                        v-model="familiar_background_form.epilepsia_choices"
                        :options="options"
                        name="flavour-1a"
                      ></b-form-checkbox-group>
                    </b-th>
                    <b-th>
                      <b-form-input
                        v-model="familiar_background_form.epilepsia"
                        type="text"
                      ></b-form-input>
                    </b-th>
                  </b-tr>
                  <b-tr>
                    <b-th>Infecciones</b-th>
                    <b-th>
                      <b-form-checkbox-group
                        v-model="familiar_background_form.infecciones_choices"
                        :options="options"
                        name="flavour-1a"
                      ></b-form-checkbox-group>
                    </b-th>
                    <b-th>
                      <b-form-input
                        v-model="familiar_background_form.infecciones"
                        type="text"
                      ></b-form-input>
                    </b-th>
                  </b-tr>
                  <b-tr>
                    <b-th>Hospitalizaciones</b-th>
                    <b-th>
                      <b-form-checkbox-group
                        v-model="
                          familiar_background_form.hospitalizaciones_choices
                        "
                        :options="options"
                        name="flavour-1a"
                      ></b-form-checkbox-group>
                    </b-th>
                    <b-th>
                      <b-form-input
                        v-model="familiar_background_form.hospitalizaciones"
                        type="text"
                      ></b-form-input>
                    </b-th>
                  </b-tr>
                  <b-tr>
                    <b-th>Transfusiones de sangre</b-th>
                    <b-th>
                      <b-form-checkbox-group
                        v-model="familiar_background_form.transfusiones_choices"
                        :options="options"
                        name="flavour-1a"
                      ></b-form-checkbox-group>
                    </b-th>
                    <b-th>
                      <b-form-input
                        v-model="familiar_background_form.transfusiones"
                        type="text"
                      ></b-form-input>
                    </b-th>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </div>
          </b-card-text>
          <b-card-footer>
            <b-button variant="primary" @click="savePerson()">Guardar</b-button>
          </b-card-footer>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  data() {
    return {
      has_background: true,
      has_familiar_background: true,
      has_triage: false,
      form: {
        birth_date: "1990-09-09"
      },
      background_form: {},
      triage_form: {
        temperature: null,
        pa: null,
        sat: null,
        fc: null,
        fr: null,
        weight: null,
        height: null,
        pab: null,
        is_pregnant: false
      },
      familiar_background_form: {
        tuberculosis_choices: [],
        soba_asma_choices: [],
        infecciones_choices: [],
        epilepsia_choices: [],
        hospitalizaciones_choices: [],
        transfusiones_choices: []
      },
      selected: [],
      options: [
        { text: "Padre", value: "P" },
        { text: "Madre", value: "M" },
        { text: "Hijo", value: "H" },
        { text: "Hija", value: "A" }
      ],

      fields: [
        {
          key: "document_type",
          label: "Tipo documento"
        },
        {
          key: "dni",
          label: "Número documento"
        },
        {
          key: "full_name",
          label: "Nombres y apellidos"
        },
        {
          key: "action",
          label: "Acciones"
        }
      ]
    };
  },
  methods: {
    getBackground() {
      ApiService.get(
        "background/search/person",
        `?person=${this.$route.params.id}`
      )
        .then(res => {
          this.background_form = res.data;
        })
        .catch(() => (this.has_background = false));
    },

    getFamiliarBackground() {
      ApiService.get(
        "familiar-background/search/person",
        `?person=${this.$route.params.id}`
      )
        .then(res => {
          this.familiar_background_form = res.data;
        })
        .catch(() => (this.has_familiar_background = false));
    },

    getPerson() {
      ApiService.get(`person/${this.$route.params.id}`).then(res => {
        this.form = res.data;
      });
    },

    makeToast(variant = null) {
      this.$bvToast.toast("Éxito al guardar", {
        title: `Todo bien`,
        variant: variant,
        solid: true
      });
    },

    saveBackground() {
      let background_method =
        this.has_background === true ? ApiService.patch : ApiService.post;
      let background_url =
        this.has_background === true
          ? `background/${this.background_form.id}/`
          : "background/";
      this.background_form.person = this.$route.params.id;
      background_method(background_url, this.background_form);
    },

    saveFamiliarBackground() {
      let familiar_background_method =
        this.has_familiar_background === true
          ? ApiService.patch
          : ApiService.post;
      let familiar_background_url =
        this.has_familiar_background === true
          ? `familiar-background/${this.familiar_background_form.id}/`
          : "familiar-background/";
      this.familiar_background_form.person = this.$route.params.id;
      familiar_background_method(
        familiar_background_url,
        this.familiar_background_form
      );
    },

    getTriage() {
      ApiService.get("triage/search", `?person=${this.$route.params.id}`)
        .then(res => {
          this.has_triage = true;
          this.triage_form = res.data;
        })
        .catch(() => (this.has_triage = false));
    },

    saveTriage() {
      this.triage_form.person = this.$route.params.id;
      ApiService.post("triage/", this.triage_form);
    },

    savePerson() {
      this.form.has_medical_record = true;
      this.saveBackground();
      this.saveFamiliarBackground();
      this.saveTriage();
      ApiService.patch(`person/${this.$route.params.id}/`, this.form).then(
        res => {
          this.form = res.data;
          this.makeToast("primary");
          this.$router.push({
            name: "create_appointment",
            query: { person: this.$route.params.id }
          });
        }
      );
    }
  },
  mounted() {
    this.getPerson();
    this.getBackground();
    this.getFamiliarBackground();
    this.getTriage();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vue Bootstrap", route: "alert" },
      { title: "Crear historia" }
    ]);
  }
};
</script>

<style scoped>
.card-header {
  padding: 1rem 2.25rem;
}
</style>
